<template>
  <Layout class="AdvertisementEdit">
    <div class="content">
      <h4 class="title">
        信息
      </h4>
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-width="80px"
        label-position="left"
      >
        <el-form-item
          label="ID"
          prop="key"
        >
          <el-input
            v-model="formData.key"
            clearable
            placeholder="Key"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="名称"
          prop="title"
        >
          <el-input
            v-model="formData.title"
            clearable
            placeholder="名称将展示在APP"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="排序"
          prop="weight"
        >
          <el-input-number
            v-model="formData.weight"
            :max="9999"
            clearable
          />
        </el-form-item>
        <el-form-item label="所属板块">
          <el-select
            v-model="formData.groupKey"
            disabled
          >
            <el-option
              v-for="(item, index) in appModuleListInfo.list"
              :key="index"
              :label="item.title"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="desc"
        >
          <el-input
            v-model="formData.desc"
            type="textarea"
            clearable
            placeholder="多行描述"
            maxlength="40"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="Tag"
          prop="tag"
        >
          <el-select
            v-model="formData.tag"
            placeholder="请选择展示类型"
            clearable
          >
            <el-option
              v-for="(item, index) in advertisementTagList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.tag === 1"
          label="URL"
          prop="url"
        >
          <el-input
            v-model="formData.url"
            clearable
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.tag === 2"
          label="关联游戏"
          prop="gameId"
        >
          <el-select
            v-model="formData.gameId"
            value-key="gameId"
            style="width: 420px;"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.gameId"
              :label="item.gameName"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="图"
          prop="adCover"
        >
          <UploadImage
            :file-list.sync="coverList"
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
            active-text="上架"
            inactive-text="下架"
          />
        </el-form-item>
      </el-form>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import { queryAdvertisementDetail, updateOrSaveAdvertisement } from '@/api/APP'
import { advertisementTagList } from '@/utils/selectOptions'
import { mapActions, mapState } from 'vuex'
import { searchGameList } from '@/api/gameList'
import { cloneDeep } from 'lodash'

export default {
  name: 'AdvertisementEdit',
  data () {
    const adCoverValidator = (rule, value, callback) => {
      if (this.coverList.length) {
        return callback()
      } else {
        callback(new Error('adCover is required!!'))
      }
    }
    return {
      advertisementTagList,
      options: [],
      coverList: [],
      loading: false,
      adKey: '',
      groupKey: '',
      isCreated: '',
      formData: {
        status: 2
      },
      updateLoading: false,
      rules: {
        key: [{ required: true }],
        title: [{ required: true }],
        weight: [{ required: true }],
        gameId: [{ required: true }],
        tag: [{ required: true }],
        url: [{ required: true }],
        adCover: [{ required: true, validator: adCoverValidator }]
      }
    }
  },
  computed: {
    ...mapState('appManage', ['appModuleListInfo'])
  },
  created () {
    this.groupKey = this.$route.query.groupKey
    this.isCreated = this.groupKey
    this.queryModuleList({ pageNum: 1, pageSize: 100 })
    if (this.isCreated) {
      this.formData.groupKey = this.groupKey
    }
    this.adKey = this.$route.query.key
    if (this.adKey) {
      queryAdvertisementDetail({
        key: this.adKey
      }).then(res => {
        if (res.code === 200) {
          this.formData = res.data.oneData
          this.coverList.push({
            key: this.formData.imagePath,
            url: this.formData.image
          })
          const { gameIdString: gameId, gameName, platform, tips, startMode, rentalSupport } = this.formData
          this.formData.gameId = { gameId, gameName, platform, tips, startMode, rentalSupport }
          this.options = [{ gameId, gameName, platform, tips, startMode, rentalSupport }]
        }
      })
    }
  },
  methods: {
    ...mapActions('appManage', ['queryModuleList']),
    remoteMethod (query) {
      if (!query) return
      this.loading = true
      searchGameList({ keyWord: query, pageNum: 1, pageSize: 30 })
        .then(res => {
          if (res.code === 200) {
            this.options = res.data.list.map(item => {
              return {
                gameId: item.gameIdStr,
                gameName: item.gameName,
                tips: item.tag,
                platform: item.platform,
                startMode: item.startMode,
                rentalSupport: item.rentalSupport
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSubmit () {
      this.$refs.form.validate().then(valid => {
        if (valid) {
          this.updateLoading = true
          const formData = this.preFormatFormData()
          updateOrSaveAdvertisement(formData)
            .then(res => {
              if (res.code === 200) {
                const message = this.isCreated ? '新建成功' : '更新成功'
                this.$notify({
                  title: '成功',
                  message: message,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        }
      })
    },
    handleCancel () {
      this.$router.go(-1)
    },
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      if (this.isCreated) {
        formData.groupKey = this.groupKey
      }
      if (formData.tag !== 1) formData.url = ''
      formData.image = this.coverList[0].key
      const game = formData.gameId
      Object.assign(formData, game)
      return formData
    }
  }
}
</script>
<style lang="less">

  .AdvertisementEdit{
    .el-input-number--medium{
      width: 217px;
    }
  }
</style>
<style scoped lang="less">
  .AdvertisementEdit{
    .content{
      /*width: 500px;*/
      margin: 50px 80px;
    }
  }
  h4.title{
    margin-bottom: 50px;
  }
  .el-input,
  .el-textarea{
    width: 420px;
  }
  .ctrl-area{
    margin-top: 80px;
    .el-button{
      padding:10px  40px;
    }
  }
</style>
